/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class style.css
 * This CSS file is intended to provide the css styles for match question page design
 *
 * @author vidhyadarshini.s@centelon.com
 * @since 22 nov 2022
 */
 
.match-the-following {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

 

.column {
  /* width: 200px; */
  padding: 10px;
  /* border: 1px solid #ccc; */
  /* border-radius: 4px; */
}

 

.left-column {
  margin-right: 10px;
}

 

.row {
  background-color:  #F8F9FA;
  padding: 20px ;
  margin-bottom: 10px;
  border: 1px solid #000000;
  border-radius: 5px;
  height: 100px;
}
.rows {
  /* background-color: #f9f9f9; */
  padding: 20px ;
  margin-bottom: 10px;
  /* border: 1px solid #ddd; */
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: 100px;
}

 

.rows:hover {
  /* background-color: #f5f5f5; */
  /* cursor: pointer; */
}

 

.row.dragging {
  opacity: 0.5;
}

 

.row.dragging:hover {
  background-color: #634699;
}

 

.row.dragging-over {
  border: 2px dashed #634699;
}

 

.row.dragging-over:hover {
  background-color: #634699;
}

 

.row.dragging-over.invalid {
  border-color: #ff0000;
}